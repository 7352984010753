export default class Storage {
  keys = {
    userData: "userData",
  };

  getUserData = () => {
    return this.getLocalItem(this.keys.userData);
  };

  setUserData = (userData) => {
    this.setLocalItem(this.keys.userData, userData);
  };

  removeUserData = () => {
    this.clearLocal();
  };

  setLocalItem = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.log(e);
    }
  };

  getLocalItem = (key) => {
    let value = "";
    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.log(e);
    }
    return value;
  };

  clearLocal = () => {
    localStorage.clear();
  };
}
