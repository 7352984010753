import { msalInstance } from "../../auth/authConfig";
import { Redirect } from "react-router-dom";
import Storage from "../../modules/Storage";
import Cookies from "../../modules/Cookies";
// import Navbar from "../../components/Navbar";

import {
  Card,
  CardContent,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";

const storage = new Storage();
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  card: {
    minWidth: 275,
    padding: theme.spacing(2),
    textAlign: "center",
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const Login = () => {
  const classes = useStyles();
  // const navigate = useNavigate();

  function handleLogin() {
    const loginRequest = {
      scopes: ["User.Read.All"],
    };
    msalInstance
      .loginPopup(loginRequest)
      .then((response) => {
        cookies.setUserSession(
          response.accessToken,
          response.account.username
        );
        storage.setUserData({
          userName: response.account.username,
          name: response.account.name,
        });
        // navigate("/project");
				<Redirect to="/project" />
        // notification.show("Welcome", response.account.name, "success");
      })
      .catch((err) => {
        console.log(err);
        // notification.show("Error", err, "error");
      });
  }
	return (
    <>
      {/* <Navbar /> */}
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h2">
              Welcome
            </Typography>
            <Typography color="textSecondary">
              Please log in with Azure AD
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleLogin}
            >
              Login with Azure AD
            </Button>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Login;
