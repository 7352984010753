export const data = [
  {
    name: "UBI Accelerator",
    instanceName: "ubi_accel-demo",
    lamdaUrl: "REACT_APP_LAMBDA_URL1",
    icon: "/reporting.png",
    description: "Experience the transformative power of data visualization at your fingertips! Unlock actionable insights and intuitive dashboards effortlessly, thanks to our proven and user-friendly reporting solution.",
    link: "https://usefulbi.com/ubi-xcelerator/",
    btntxt:"Try it Out", 
    link2:"http://dev-accelerator.usefulbi.com/",
    fontSize: '0.9rem',
    imgwidth:'100px',
    imgheight:'100px',
    // tag: ['Autom1', 'Autom2'],
    tag: ["Lifesciences","BFSI", "Automotive", "Data Engineering"],
    type: "industry"
  },
  {
    name: "Reporting Capabilities",
    icon: "/reporting.png",
    description: "Experience the transformative power of data visualization at your fingertips! Unlock actionable insights and intuitive dashboards effortlessly, thanks to our proven and user-friendly reporting solution.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt:"Try it Out", 
    link2:"/tableau",
    fontSize: '0.9rem',
    imgwidth:'100px',
    imgheight:'100px',
    tag: ["Lifesciences","BFSI","Automotive","Business Intelligence"],
    type: "industry"
  },
  {
    name: "Integration Capabilities",
    icon: "/Mulesoft.png",
    description: "Unleash the full potential of seamless integration with a single click! Click the card to explore our extensive capabilities and revolutionize your data ecosystem.",
    btntxt:"Try it Out", 
    link: "https://www.usefulbi.com/services-mulesoft/",
    link2:"https://www.usefulbi.com/services-data-integration/",
    fontSize: '0.9rem',
    imgwidth:'100px',
    imgheight:'91px',
    tag: ["Lifesciences","BFSI","Automotive","Data Science"],
    type: "industry"
  },
  {
    key: 5,
    name: "UBI Pro Launch",
    instanceName: "PharmLaunch",
    lamdaUrl: "REACT_APP_LAMBDA_URL2",
    icon: "/Pharma.png",
    description:
      "Streamline your pharma drug launch planning with UBI Pharma Launch - the cutting-edge software solution designed to optimize efficiency, enhance collaboration, and drive successful product launches.",
    btntxt: "Try it Out",
    link2: "https://pharmlaunch.usefulbi.com/",
    link: "https://usefulbi.com/solutions-pharmlaunch/",
    fontSize: "0.9rem",
    imgwidth: "95px",
    imgheight: "91px",
    tag: ["Lifesciences","Project Planning", "Automotive", "BFSI"],
    type: "industry"
  },
  {
    name: "Site Data Management",
    instanceName: "Clinical_data_management",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Clinical-Data.png",
    description:
      "Simplify and enhance your clinical data management with our comprehensive solution, streamlining data collection, organization, and analysis for efficient and compliant clinical research processes with outmost ease.",
    btntxt: "Try it Out",
    link: "https://usefulbi.com/clinical-data-management/",
    link2:
      "https://cdm.usefulbi.com/",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences","BFSI","Automotive","Data Engineering"],
    type: "usecase",
  },
  {
    name: "Conversational AI",
    instanceName: "protocol-ai-app",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Chatbot_1.png",
    description:
      "Transform patient interactions with our cutting-edge conversational AI solution. Seamlessly personalize conversations to deliver exceptional user experiences and engagement.",
    link2: "https://protocolai.usefulbi.com/",
    btntxt: "Try it Out",
    link: "https://usefulbi.com/chatbot-using-conversational-ai/",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "83px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Gen AI"],
    type: "usecase",
  },
  {
    name: "IntelliSearch AI",
    instanceName: "protocol-ai-app",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/AWS.png",
    description:
      "Supercharge your search capabilities with IntelliSearch AI. Leverage our solution to unlock powerful and intelligent search functionalities, enabling effortless retrieval of relevant information ,informed decison making.",
    btntxt: "Try it Out",
    link: "https://www.usefulbi.com/chatbot-using-conversational-ai/",
    link2: "https://protocolai.usefulbi.com/",
    fontSize: "0.9rem",
    imgwidth: "90px",
    imgheight: "91px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Data Science", "Gen AI"],
    type: "usecase",
  },
];