export const data = [
  {
    // key: 0,
    name: "UBI Accelerator",
    instanceName: "ubi_accel-demo",
    lamdaUrl: "REACT_APP_LAMBDA_URL1",
    icon: "/accelerator.png",
    description:
      "Efficiently automate data pipelines for data lakes and warehouses, with seamless onboarding, UI-driven metadata configuration, and effective delta detection using key attributes.",
    btntxt: "Product images",
    link: "https://usefulbi.com/ubi-xcelerator/",
    link2:"http://dev-accelerator.usefulbi.com/",
    fontSize: "0.8rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences","BFSI", "Automotive", "Data Engineering"],
    type: "usecase",
  },
  {
    name: "Site Viability Tool",
    instanceName: "csvt",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Clinical.png",
    description:
      "Streamline AI model serving with our advanced solution, enabling efficient deployment, scaling, and monitoring of models for seamless integration into your company's operations.",
    btntxt: "Product images",
    link: "https://usefulbi.com/clinical-operations-study-viability/",
    link2:
      "https://csvt.usefulbi.com/",
    fontSize: "0.8rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences","Data Engineering"],
    type: "usecase",
  },
  {
    name: "Integration Capabilities",
    icon: "/Mulesoft.png",
    description:
      "Unleash the full potential of seamless integration with a single click! Click the card to explore our extensive capabilities and revolutionize your data ecosystem.",
    btntxt: "Try it Out",
    link: "https://www.usefulbi.com/services-mulesoft/",
    link2: "https://www.usefulbi.com/services-data-integration/",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences","BFSI","Automotive","Data Science"],
    type: "usecase",
  },
  {
    name: "Site Data Management",
    instanceName: "Clinical_data_management",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Clinical-Data.png",
    description:
      "Simplify and enhance your clinical data management with our comprehensive solution, streamlining data collection, organization, and analysis for efficient and compliant clinical research processes with outmost ease.",
    btntxt: "Try it Out",
    link: "https://usefulbi.com/clinical-data-management/",
    link2:
      "https://cdm.usefulbi.com/",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences","BFSI","Automotive","Data Engineering"],
    type: "usecase",
  },
];
