import Navbar from './Components/Navbar';
import Card from '../../components/cards/Card';
import { data } from './data';
import header from './Components/Images/Header.png'
import Card1 from './Components/Card1';


function App() {
  const myStyle={
    backgroundImage:`url(${process.env.PUBLIC_URL+ "/automotive_nav.png"})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '380px',
      width: '100%',
};
  return (
    <div className="App">
      {/* <Navbar/>
       */}
        <div className="banner-image1 d-flex justify-content-center align-items-center"  style={myStyle}></div>

      {/* <div className="container-md"> */}
        <div className="row justify-content-center">
          {/* <Card1/> */}
        {data.map((x) => (
          <Card
            name={x.name}
            icon={x.icon}
            description={x.description}
            link={x.link}
            link2={x.link2}
            btntxt={x.btntxt}
            fontSize={x.fontSize}
            imgheight={x.imgheight}
            imgwidth={x.imgwidth}
            tag = {x.tag}
            {
              ...x
            }
          />
          ))}
      </div>
      {/* </div> */}
    <br/>
    <br/>
    <br/>
    <br/>
    </div>
    
  );
}

export default App;