import jsCookie from "js-cookie";

//jsCookie.set('name', 'value', { expires: 7, path: '' })

export default class Cookies {
  keys = {
    accessToken: "accessToken",
    userId: "userId",
    userName: "userName",
  };

  getUserSession = () => {
    return [
      this.getCookie(this.keys.accessToken),
      this.getCookie(this.keys.userId),
      this.getCookie(this.keys.userName),
    ];
  };

  setUserSession = (accessToken, userId, userName) => {
    this.setCookie(this.keys.accessToken, accessToken);
    this.setCookie(this.keys.userId, userId);
    this.setCookie(this.keys.userName, userName);
  };

  removeUserSession = () => {
    this.removeCookie(this.keys.accessToken);
    this.removeCookie(this.keys.userId);
    this.removeCookie(this.keys.userName);
    window.location.replace("/login");
  };

  getUserToken = () => {
    return this.getCookie(this.keys.accessToken);
  };

  setCookie = (
    key,
    value,
    expires = 3,
    path = "",
    domain = `.${window.location.hostname}`
  ) => {
    try {
      jsCookie.set(key, value, {
        expires: expires,
        path: path,
        domain: domain,
      });
    } catch (e) {
      console.log(e);
    }
  };

  getCookie = (key) => {
    let value = "";
    try {
      value = jsCookie.get(key);
    } catch (e) {
      console.log(e);
    }
    return value;
  };

  removeCookie = (key, path = "", domain = `.${window.location.hostname}`) => {
    jsCookie.remove(key, { path: path, domain: domain });
  };
  clearCookies = () => {
    jsCookie.get();
  };
}
