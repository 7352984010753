export const data = [
  {
    key: 0,
    name: "UBI Accelerator",
    instanceName: "ubi_accel-demo",
    lamdaUrl: "REACT_APP_LAMBDA_URL1",
    icon: "/accelerator.png",
    description:
      "Efficiently automate data pipelines for data lakes and warehouses, with seamless onboarding, UI-driven metadata configuration, and effective delta detection using key attributes.",
    btntxt: "Product images",
    link: "https://usefulbi.com/ubi-xcelerator/",
    link2: "http://dev-accelerator.usefulbi.com/",
    fontSize: "0.8rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Data Engineering"],
    type: "industry",
  },
  {
    key: 1,
    name: "Site Viability Tool",
    instanceName: "csvt",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Clinical.png",
    description:
      "Streamline AI model serving with our advanced solution, enabling efficient deployment, scaling, and monitoring of models for seamless integration into your company's operations.",
    btntxt: "Product images",
    link: "https://usefulbi.com/clinical-operations-study-viability/",
    link2:
      "https://csvt.usefulbi.com/",
    fontSize: "0.8rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences", "Data Engineering"],

    type: "industry",
  },
  {
    key: 2,
    name: "Conversational AI",
    instanceName: "protocol-ai-app",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Chatbot_1.png",
    description:
      "Transform patient interactions with our cutting-edge conversational AI solution. Seamlessly personalize conversations to deliver exceptional user experiences and engagement.",
    link: "https://usefulbi.com/chatbot-using-conversational-ai/",
    btntxt: "Try it Out",
    link2: "https://protocolai.usefulbi.com/",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "83px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Gen AI"],
    type: "industry",
  },
  {
    key: 3,
    name: "Site Data Management",
    instanceName: "Clinical_data_management",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Clinical-Data.png",
    description:
      "Simplify and enhance your clinical data management with our comprehensive solution, streamlining data collection, organization, and analysis for efficient and compliant clinical research processes with outmost ease.",
    btntxt: "Try it Out",
    link: "https://usefulbi.com/clinical-data-management/",
    link2:
      "https://cdm.usefulbi.com/",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "91px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Data Engineering"],
    type: "industry",
  },
  {
    key: 4,
    name: "IntelliSearch AI",
    instanceName: "protocol-ai-app",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/AWS.png",
    description:
      "Supercharge your search capabilities with IntelliSearch AI. Leverage our solution to unlock powerful and intelligent search functionalities, enabling effortless retrieval of relevant information ,informed decison making.",
    btntxt: "Try it Out",
    link: "https://www.usefulbi.com/chatbot-using-conversational-ai/",
    link2: "https://protocolai.usefulbi.com/",
    fontSize: "0.9rem",
    imgwidth: "90px",
    imgheight: "91px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Data Science", "Gen AI"],
    type: "industry",
  },
  {
    key: 5,
    name: "UBI Pro Launch",
    instanceName: "PharmLaunch",
    lamdaUrl: "REACT_APP_LAMBDA_URL2",
    icon: "/Pharma.png",
    description:
      "Streamline your pharma drug launch planning with UBI Pharma Launch - the cutting-edge software solution designed to optimize efficiency, enhance collaboration, and drive successful product launches.",
    btntxt: "Try it Out",
    link2: "https://pharmlaunch.usefulbi.com/",
    link: "https://usefulbi.com/solutions-pharmlaunch/",
    fontSize: "0.9rem",
    imgwidth: "95px",
    imgheight: "91px",
    tag: ["Lifesciences", "Project Planning", "Automotive", "BFSI"],
    type: "industry",
  },
  {
    key: 6,
    name: "Oncology Trials Case Study",
    icon: process.env.PUBLIC_URL + "/Oncology.png",
    description:
      "Discover the future of cancer research with our Tableau solution! Visualize oncology trial data, uncover hidden patterns, and make informed decisions quickly with our intuitive, interactive dashboards.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on oncology trials.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["Lifesciences", "Business Intelligence", "Tableau"],
    type: "industry",
  },
  {
    key: 7,
    name: "Open Payments Case Study",
    icon: process.env.PUBLIC_URL + "/Money.png",
    description:
      "Transform your financial analysis with our advanced Tableau dashboards. Easily visualize Open Payments data, uncover insights into financial relationships and compliance trends, and drive strategic decisions with clear, actionable visuals.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on open payments.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["BFSI", "Tableau", "Business Intelligence"],
    type: "industry",
  },
  {
    key: 8,
    name: "Lot Genealogy Case Study",
    icon: process.env.PUBLIC_URL + "/Genology.png",
    description:
      "Explore product lot connections with our Tableau visualization tools. Seamlessly trace and analyze lot genealogy, identify critical patterns, and enhance quality control with dynamic, user-friendly dashboards.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on lot geneology.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["Lifesciences", "Business Intelligence", "Tableau"],
    type: "industry",
  },
  {
    key: 9,
    name: "Demo on FDA Adverse Events",
    icon: process.env.PUBLIC_URL + "/FDA.png",
    description:
      "Experience the power of Tableau in monitoring FDA adverse events with our demo. Effortlessly visualize and interact with data, identify trends and correlations, and streamline reporting with clear, actionable insights.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on FDA adverse events.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["Lifesciences", "Business Intelligence", "Tableau"],
    type: "industry",
  },
];
