import React, { useState, useEffect } from 'react';
import Card from '../../components/cards/Card';
import { data } from './data';
import { Link } from 'react-router-dom';
import Storage from '../../modules/Storage';
import Cookies from '../../modules/Cookies';
import "./BFSI.css";

const storage = new Storage();
const cookies = new Cookies();

const myStyle = {
  backgroundImage: `url(${process.env.PUBLIC_URL + "/bfsi_navbar.png"})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '380px',
  width: '100%',
};

function BFSI() {
  
  return (
    <div className="App">
      <div className="banner-image1 d-flex justify-content-center align-items-center" style={myStyle}></div>

      {/* <div className="container-md"> */}
        <div className="row justify-content-center">
          {data.map((x) => (
            <Card
              key={x.key}
              name={x.name}
              icon={x.icon}
              description={x.description}
              link={x.link}
              link2={x.link2}
              btntxt={x.btntxt}
              fontSize={x.fontSize}
              imgheight={x.imgheight}
              imgwidth={x.imgwidth}
              tag={x.tag}
              instanceName={x.instanceName}
              {
                ...x
              }
            />
          ))}
        </div>
      {/* </div> */}

      <Link to="/login">
        <button className="hidden-xyz-button">xyz</button>
      </Link>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default BFSI;



// import Navbar from './Components/Navbar';
// import Card from '../../components/cards/Card';
// import { data } from './data';
// import header from './Components/Images/Header.png'
// import Card1 from './Components/Card1';
// import {Link} from 'react-router-dom';
// import "./BFSI.css"

// const myStyle={
//   backgroundImage:`url(${process.env.PUBLIC_URL+ "/bfsi_navbar.png"})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//     height: '380px',
//     width: '100%',
// };

// function App() {
//   return (
//     <div className="App">
//       {/* <Navbar/> */}
//       <div className="banner-image1 d-flex justify-content-center align-items-center"  style={myStyle}></div>

//       <div className="container-md">
//         <div className="row justify-content-center">
//           {/* <Card1/> */}
//         {data.map((x) => (
//           <Card
//             name={x.name}
//             icon={x.icon}
//             description={x.description}
//             link={x.link}
//             link2={x.link2}
//             btntxt={x.btntxt}
//             fontSize={x.fontSize}
//             imgheight={x.imgheight}
//             imgwidth={x.imgwidth}
//             tag = {x.tag}
//           />
//           ))}
//         </div>
//       </div>
//       <Link to="/login">
//         <button className="hidden-xyz-button">xyz</button>
//       </Link>
//       <br />
//       <br />
//       <br />
//       <br />
//     </div>
//   );
// }

// export default App;
