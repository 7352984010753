export const data = [
  // {
  //   // key: 0,
  //   name: "UBI Accelerator",
  //   icon: "/accelerator.png",
  //   description:
  //     "Efficiently automate data pipelines for data lakes and warehouses, with seamless onboarding, UI-driven metadata configuration, and effective delta detection using key attributes.",
  //   btntxt: "Product images",
  //   link: "https://usefulbi.com/ubi-xcelerator/",
  //   link2:"http://dev-accelerator.usefulbi.com/",
  //   fontSize: "0.8rem",
  //   imgwidth: "100px",
  //   imgheight: "91px",
  //   tag: ["Lifesciences","BFSI", "Automotive", "Data Engineering"],
  //   type: "industry"
  // },
  {
    name: "Reporting Capabilities",
    icon: "/reporting.png",
    description: "Experience the transformative power of data visualization at your fingertips! Unlock actionable insights and intuitive dashboards effortlessly, thanks to our proven and user-friendly reporting solution.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt:"Try it Out", 
    link2:"/tableau",
    fontSize: '0.9rem',
    imgwidth:'100px',
    imgheight:'100px',
    tag: ["Lifesciences","BFSI","Automotive","Business Intelligence"],
    type: "industry"
  },
  {
    name: "Integration Capabilities",
    icon: "/Mulesoft.png",
    description: "Unleash the full potential of seamless integration with a single click! Click the card to explore our extensive capabilities and revolutionize your data ecosystem.",
    btntxt:"Try it Out", 
    link: "https://www.usefulbi.com/services-mulesoft/",
    link2:"https://www.usefulbi.com/services-data-integration/",
    fontSize: '0.9rem',
    imgwidth:'100px',
    imgheight:'91px',
    tag: ["Lifesciences","BFSI","Automotive","Data Science"],
    type: "industry"
  },
];