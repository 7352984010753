import Navbar from "./Components/Navbar";
// import MultipleSelectCheckmarks from "../../search-bar/search-bar";
import Card from "../../components/cards/Card";
import { data } from "./data";
import header from "./Components/Images/Header.png";
//import Card1 from "./Components/Card1";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
// import "./search-bar.css";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const uniqueTags = Array.from(new Set(data.map((t) => t.tag).flat()));
  const [filteredCards, setFilteredCards] = useState(data);
  //console.log(filteredCards);

  useEffect(() => {
    setFilteredCards(data); // Initialize with all cards
  }, []);

  const handleSearch = (selectedTags) => {
    if (selectedTags.length > 0) {
      const filtered = data.filter((card) =>
        selectedTags.some((tag) => card.tag && card.tag.includes(tag))
      );
      setFilteredCards(filtered);
    } else {
      setFilteredCards(data); // Show all cards if no tag is selected
    }
  };

  const myStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + "/lifesciences_nav.png"})`,
    //marginTop: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    //backgroundPosition: "center center", // Ensure the image is centered
    height: "370px",
    width: "100%",
    //transition: "background-position 0.3s ease", // Smooth transition on scrol
  };
  return (
    <div className="App">
      <ScrollToTop />
      <div
        className="banner-image1 d-flex  justify-content-center align-items-center"
        style={myStyle}
      ></div>
      {/* <Navbar /> */}
      {/* <div className="search">
        <div className="search-bar-container">
          <MultipleSelectCheckmarks
            names={uniqueTags}
            onSearch={handleSearch}
          />
        </div>
        </div>
        <div className="row justify-content-center">
          {filteredCards.map((x) => (
            <Card
               key={x.id}
              name={x.name}
              icon={x.icon}
              description={x.description}
              link={x.link}
              link2={x.link2}
              btntxt={x.btntxt}
              fontSize={x.fontSize}
              imgheight={x.imgheight}
              imgwidth={x.imgwidth}
              tag={x.tag}
            />
          ))}
        </div> */}

      {/* <div className="container-md"> */}
        <div className="row justify-content-center">
          {/* <Card1/> */}
          {data.map((x, index) => (
            <Card
              key={`${index}_usecase`}
              name={x.name}
              icon={x.icon}
              description={x.description}
              link={x.link}
              link2={x.link2}
              btntxt={x.btntxt}
              fontSize={x.fontSize}
              imgheight={x.imgheight}
              imgwidth={x.imgwidth}
              tag={x.tag}
              {
                ...x
              }
            />
          ))}
        </div>
      {/* </div> */}
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default App;
