export const data = [
  // {
  //   // key: 0,
  //   name: "UBI Accelerator",
  //   icon: "/accelerator.png",
  //   description:
  //     "Efficiently automate data pipelines for data lakes and warehouses, with seamless onboarding, UI-driven metadata configuration, and effective delta detection using key attributes.",
  //   btntxt: "Product images",
  //   link: "https://usefulbi.com/ubi-xcelerator/",
  //   link2:"http://dev-accelerator.usefulbi.com/",
  //   fontSize: "0.8rem",
  //   imgwidth: "100px",
  //   imgheight: "91px",
  //   tag: ["Lifesciences", "BFSI", "Automotive", "Data Engineering"],
  //   type: "usecase",
  // },
  {
    name: "Reporting Capabilities",
    icon: "/reporting.png",
    description:
      "Experience the transformative power of data visualization at your fingertips! Unlock actionable insights and intuitive dashboards effortlessly, thanks to our proven and user-friendly reporting solution.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: "/tableau",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Business Intelligence"],
    type: "usecase",
  },
  {
    name: "Oncology Trials Case Study",
    icon: process.env.PUBLIC_URL + "/Oncology.png",
    description:
      "Discover the future of cancer research with our Tableau solution! Visualize oncology trial data, uncover hidden patterns, and make informed decisions quickly with our intuitive, interactive dashboards.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on oncology trials.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["Lifesciences", "Business Intelligence", "Tableau"],
    type: "usecase",
  },
  {
    name: "Open Payments Case Study",
    icon: process.env.PUBLIC_URL + "/Money.png",
    description:
      "Transform your financial analysis with our advanced Tableau dashboards. Easily visualize Open Payments data, uncover insights into financial relationships and compliance trends, and drive strategic decisions with clear, actionable visuals.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on open payments.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["BFSI", "Tableau", "Business Intelligence"],
    type: "usecase",
  },
  {
    name: "Lot Genealogy Case Study",
    icon: process.env.PUBLIC_URL + "/Genology.png",
    description:
      "Explore product lot connections with our Tableau visualization tools. Seamlessly trace and analyze lot genealogy, identify critical patterns, and enhance quality control with dynamic, user-friendly dashboards.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on lot geneology.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["Lifesciences", "Business Intelligence", "Tableau"],
    type: "usecase",
  },
  {
    name: "Demo on FDA Adverse Events",
    icon: process.env.PUBLIC_URL + "/FDA.png",
    description:
      "Experience the power of Tableau in monitoring FDA adverse events with our demo. Effortlessly visualize and interact with data, identify trends and correlations, and streamline reporting with clear, actionable insights.",
    link: "https://usefulbi.com/business-intelligence/",
    btntxt: "Try it Out",
    link2: process.env.PUBLIC_URL + "/UBI demo on FDA adverse events.html",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "100px",
    tag: ["Lifesciences", "Business Intelligence", "Tableau"],
    type: "usecase",
  },
];
