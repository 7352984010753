import React, { useState, useEffect, Children } from "react";
import "./Card1.css";
import vector from "./Vector 5.png";
// import Storage from "../../modules/Storage";
// import Cookies from "../../modules/Cookies";
const Card = (props) => {
  // console.log(props.icon);
  // const isPharmLaunch =
  //   props.name === "UBI Pharma Launch" || props.name === "IntelliSearch AI";

  const tagsContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center", // Center-align tags horizontally
    gap: "4px", // Optional: Space between tags
    padding: "8px 0", // Optional: Add padding for spacing
    paddingTop: "50px",
    //position: "absolute",
    bottom: "16px",
    marginTop: "auto",
  };

  const [isReadMore, setIsReadMore] = useState(false);

  // Split the description into words
  const words = props.description.split(" ");

  // Check if the description exceeds 25 words
  const isLongDescription = words.length > 20;

  // Truncate the description if it exceeds 25 words
  const truncatedDescription = words.slice(0, 20).join(" ");

  // Toggle function to handle "Read More" and "Show Less"
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // const storage = new Storage();
  // const cookies = new Cookies();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Function to get a cookie value by name
    // const getCookie = (name) => {
    //   const value = `; ${document.cookie}`;
    //   const parts = value.split(`; ${name}=`);
    //   if (parts.length === 2) return parts.pop().split(";").shift();
    // };

    // Check if the user is logged in by verifying the stored user data
    const userData = localStorage.getItem("userData"); // Get from localStorage

    if (userData) {
      // console.log("User data from localStorage:", JSON.parse(userData));
      setIsLoggedIn(true);
    }
    // } else {
    //   const userSession = getCookie("userSession"); // Get from cookies
    //   if (userSession) {
    //     console.log("User session from cookies:", JSON.parse(userSession));
    //     setIsLoggedIn(true);
    //   }
    // }
  }, []);

  // console.log(props);
  const envURL = props.lamdaUrl;
  const LambdaUrl = process.env[envURL];
  // console.log(LambdaUrl);
  const [currentAction, setAction] = useState("");
  const [loading, setLoading] = useState(false);

  const sendRequestToLambda = async (curr_Action) => {
    if(LambdaUrl) {
      setLoading(true);
      
      try {
      const response = await fetch(LambdaUrl, {
        method: "POST",
        body: JSON.stringify({
          Instance_name: props.instanceName,
          action: curr_Action,
        }),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error || "Network response was not ok");
      }

      const data = await response.json();
      const words = data.message.split(" ");
      console.log(data.message);

      const lastWord = words[words.length - 1];
      setAction(lastWord);
      if (lastWord === "started." || lastWord === "running.") {
        curr_Action = "stop";
      } else {
        curr_Action = "start";
      }
    } catch (error) {
      console.error("Error sending request to Lambda:", error);
    }finally {
      setLoading(false); // Ensure loading state is reset after request
    }
  }
    else
    {
      console.log("no lambda url");
    }
  };

  useEffect(() => {
    sendRequestToLambda("status");
  }, []);

  return (
    <>
      <div className="col-12 col-md-12 col-lg-6 col-xl-4 d-flex justify-content-center g-5">
        <div
          className="cardContainer1 container2"
          style={{
            width: "24rem",
            // display: "flex",
            // flexDirection: "column",
            // overflow: "hidden",
            // height: "30rem",
            // minHeight: "10px",
            // marginRight: "1%",
            // border: "1px solid black",
            // borderradius: "10px",
            // transform: "scale(1.05)",
            // transition: "transform 0.3s, box-shadow 0.3s",
            // paddingBottom: "20px",
            // background: "red",
          }}
        >
          <div className="circleImage">
            <img
              src={process.env.PUBLIC_URL + props.icon}
              alt="..."
              className="img1"
              style={{
                width: props.imgwidth,
                height: props.imgheight,
              }}
            />
          </div>
          {/* <div className="cardContainer1" style={{}}> */}
          <div
            className="card my-card"
            id="fav-card"
            style={{
              width: "24rem",
              display: "flex",
              flexDirection: "column",
              // overflow: "hidden",
              height: !isReadMore ? "33rem" : "34rem",
              // minHeight: !isReadMore ? "auto" : "500px",
              // marginRight: "1%",
              border: "1px solid black",
              borderradius: "10px",
              // transform: "scale(1.05)",
              // transition: "transform 0.3s, box-shadow 0.3s",
              paddingBottom: "20px",
            }}
          >
            <div
              className="card-body d-flex flex-column"
              id="card-body"
              // style={{ paddingtop: "40%" }}
            >
              <h3
                className="card-title text-center"
                style={{
                  color: "#3993DD",
                  marginTop: "-35px",
                  marginBottom: "10px",
                }}
              >
                {props.name}
              </h3>
              <img src={vector} alt="..." style={{ marginBottom: "15px" }} />
              <div style={{}}>
                <p>
                  {isReadMore ? props.description : truncatedDescription}
                  {isLongDescription && (
                    <span
                      onClick={toggleReadMore}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      {isReadMore ? " Show Less" : "... Read More"}
                    </span>
                  )}
                </p>
              </div>
              <div className="tags" style={tagsContainerStyle}>
                {props.tag &&
                  props.tag.map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                    </span>
                  ))}
              </div>
              {isLoggedIn && props.instanceName && (
                <button
                  style={{
                    padding: "5px 8px", // Reduced left and right padding
                    fontSize: "17px", // Small font size
                    borderRadius: "5px", // Rounded corners
                    backgroundColor:
                      loading ? "#3993DD" : (currentAction === "stopped." ? "green" : "red"),
                    color: "white", // White text color
                    border: "none", // No border
                    cursor: "pointer", // Pointer cursor on hover
                    transition: "background-color 0.3s", // Smooth background color transition
                    margin: "0 auto", // Center the button horizontally
                    display: "block",
                  }}
                  onClick={() =>
                    sendRequestToLambda(
                      currentAction === "stopped." ? "start" : "stop"
                    )
                  }
                >
                  {loading ? 'Loading...' : (currentAction === "stopped." ? "START" : "STOP")}
                </button>
              )}
              <div
                className="mt-auto d-flex justify-content-center"
                style={{
                  bottom: "-17px",
                  position: "absolute",
                  paddingLeft: "15px",
                }}
              >
                <a
                  href={props.link2}
                  target="_blank"
                  className="btn btn-dark d-flex justify-content-center align-items-center mx-2"
                  style={{
                    backgroundColor: "white",
                    minWidth: "145px",
                    fontSize: "0.85rem",
                    height: "35px",
                    lineHeight: "15px",
                  }}
                >
                  Demo
                </a>
                <a
                  href={props.link}
                  target="blank"
                  className="btn btn-dark d-flex justify-content-center align-items-center mx-2"
                  style={{
                    backgroundColor: "white",
                    minWidth: "145px",
                    fontSize: "0.85rem",
                  }}
                >
                  Know More
                </a>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Card;
