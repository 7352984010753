export const data = [
  {
    name: "IntelliSearch AI",
    instanceName: "protocol-ai-app",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/AWS.png",
    description:
      "Supercharge your search capabilities with IntelliSearch AI. Leverage our solution to unlock powerful and intelligent search functionalities, enabling effortless retrieval of relevant information ,informed decison making.",
    btntxt: "Try it Out",
    link: "https://www.usefulbi.com/chatbot-using-conversational-ai/",
    link2: "https://protocolai.usefulbi.com/",
    fontSize: "0.9rem",
    imgwidth: "90px",
    imgheight: "91px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Data Science", "Gen AI"],
    type: "usecase",
  },
  {
    name: "Conversational AI",
    instanceName: "protocol-ai-app",
    lamdaUrl: "REACT_APP_LAMBDA_URL3",
    icon: "/Chatbot_1.png",
    description:
      "Transform patient interactions with our cutting-edge conversational AI solution. Seamlessly personalize conversations to deliver exceptional user experiences and engagement.",
    link2: "https://protocolai.usefulbi.com/",
    btntxt: "Try it Out",
    link: "https://usefulbi.com/chatbot-using-conversational-ai/",
    fontSize: "0.9rem",
    imgwidth: "100px",
    imgheight: "83px",
    tag: ["Lifesciences", "BFSI", "Automotive", "Gen AI"],
    type: "usecase",
  },
];
