import React, { useState } from "react";
import { createTheme,ThemeProvider, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Autocomplete, TextField } from "@mui/material";
import Popper from '@mui/material/Popper';
import Button from "@mui/material/Button";
import "./search-bar.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = Array.from(new Set(data.map((t) => t.tag).flat()));

function getStyles(name, tagName, theme) {
  return {
    fontWeight:
      tagName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// const theme = createTheme({
//   components: {
//     MuiAutocomplete: {
//       styleOverrides: {
//         popper: {
//           zIndex: 9999, // Ensure it's above other elements
//         },
//         paper: {
//           marginTop: '5px 0px 10px 10px', // Add margin to push it below the input
//         },
//       },
//     },
//   },
// });

const CustomPopper = (props) => {
  return (
    <Popper
      {...props}
      placement="bottom-start" // Ensures the dropdown appears below
      modifiers={[
        {
          name: 'flip',
          enabled: false, // Disable the flipping behavior
        },
        {
          name: 'preventOverflow',
          enabled: true, // Ensure it stays within the screen
        },
      ]}
    />
  );
};

export default function MultipleSelectChip({ names, onSearch }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const theme = useTheme();
  const [tagName, setTagName] = React.useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   // Ensure `value` is defined and handle both autofill and normal cases
  //   const selectedTags = Array.isArray(value) ? value : [];

  //   setTagName(selectedTags);
  //   //onSearch(selectedTags);
  // };
  // console.log(newValue);

  const handleChange = (event, newValue) => {
    setTagName(newValue);
    if(newValue.length === 0){
      handleClearAll();
    }
    
  };

  const handleSearch = () => {
    onSearch(tagName);
  };

  const handleClearAll = () => {
    setTagName([]);
    onSearch([]);
  };

  const shouldHideLabel = isFocused || tagName.length > 0;

  // const [isSearchDisabled, setIsSearchDisabled] = useState(true);

  // useEffect(() => {
  //   if (tagName.length === 0) {
  //     setIsSearchDisabled(true);
  //   } else {
  //     setIsSearchDisabled(false);
  //   }
  // }, [tagName]);

  return (
    <div>
      <FormControl sx={{ m: 1, width: 600 }}>
        {/* Conditionally render the label based on focus state */}
        {/* {!shouldHideLabel && (
          <InputLabel id="demo-multiple-chip-label">
            Filter By Category
          </InputLabel>
        )} */}
        {/* <ThemeProvider theme={theme}> */}
      <Autocomplete
        multiple
        id="tags-filled"
        options={names}
        getOptionLabel={(option) => option}
        value={tagName}
        PopperComponent={CustomPopper}
        onChange={handleChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            multiple
            {...params}
            variant="outlined"
            label="Filter By Category"
            placeholder="Search tags"
          />
        )}
        style={{ width: 600, margin: "0px" }}
      />
    {/* </ThemeProvider> */}
        {/* <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={tagName}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          //input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap"}}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, tagName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select> */}
        
      </FormControl>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          padding: "10px 20px",
          margin: "12.5px 10px 10px 10px",
        }}
        onClick={handleSearch}
        // disabled={isSearchDisabled}
      >
        Filter
      </Button>
      {/* {tagName.length !== 0 && (
        <Button
          variant="outlined"
          style={{
            color: "#007bff",
            padding: "6px 6px",
            margin: "12.5px 10px 10px 10px",
            opacity: tagName.length !== 0 ? 1 : 0, // Control visibility
            transform: tagName.length !== 0 ? "scale(1)" : "scale(0.95)", // Slight scale for effect
            transition: "opacity 0.3s ease, transform 0.3s ease", // Smooth transition
          }}
          onClick={handleClearAll}
        >
          Clear All
        </Button>
      )} */}
    </div>
  );
}
