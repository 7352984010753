import { PublicClientApplication } from "@azure/msal-browser";

const client_Id = process.env.REACT_APP_SSO_CLIENT_ID;
const redirect_URL = process.env.REACT_APP_SSO_REDIRECT_URL;
const tenant_URL = process.env.REACT_APP_SSO_TENANT_ID;

export const msalConfig = {
  auth: {
    clientId: client_Id,
    authority:
      `https://login.microsoftonline.com/${tenant_URL}`,
    redirectUri: redirect_URL,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
// await msalInstance.initialize();
msalInstance
  .initialize()
  .then(() => console.log("MSAL initialized"))
  .catch((err) => console.err(err));